<template>
  <a-spin tip="请稍等..."
          :spinning="isLoading">

    <div class="customer-state">
      <div class="search-header">
        <div class="title-mark">客户阶段</div>
        <a-button type="primary"
                  centered
                  style="width: 146px;height: 36px;border-radius:4px"
                  @click="editEvent('')">
          <PlusOutlined />添加阶段
        </a-button>
      </div>

      <a-table id="mytb"
               :columns="columns"
               :data-source="rowsData.stagesData"
               :scroll="{ x: '100%', y: 'calc(100vh - 322px)' }"
               :pagination="false"
               row-key="id">
        <template #phaseName="{ record }">
          <span>{{ record.name }} </span>
        </template>
        <template #phaseDescribe="{ record }">
          <span>{{ record.remark }}</span>
        </template>
        <template #clientNum="{ record }">
          <div class="send-message">{{ record.count }}</div>
        </template>
        <template #action="{ record }">
          <a-button type="link"
                    @click="editEvent(record)">编辑</a-button>
          <a-popconfirm title="确定删除吗？"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="deleteStageBefore(record)">
            <a-button class="ml3 gray"
                      type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
      <a-modal title="提示"
               v-model:visible="hint"
               width="396px"
               :confirm-loading="confirmLoading"
               @cancel="closeHint"
               class="customer-state-hint">
        <p class="customer-state-deleteHint">
          客户阶段<span class="customer-state-repeat"> [{{ nameData }}] </span>下有<span
                class="customer-state-repeat">{{ countData }}</span>个客户，请将客户转移至其它阶段后再删除。
        </p>
        <template #footer>
          <a-button key="submit"
                    type="primary"
                    :loading="loading"
                    @click="handleSend2">确定</a-button>
        </template>
      </a-modal>
      <customer-model-edit v-if="editVisible"
                           :formStateData="formState"
                           :editVisible="editVisible"
                           @afterClose="afterClose">
      </customer-model-edit>
    </div>
  </a-spin>
</template>

<script>
import CustomerStateApi from "@/service/api/CustomerStateApi.js";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import CustomerModelEdit from "./CustomerModelEdit.vue";
import Sortable from "sortablejs";
import { message } from "ant-design-vue";
import _ from "lodash";

export default defineComponent({
  name: "CustomerGroup",
  components: {
    PlusOutlined,
    CustomerModelEdit,
  },

  data() {
    const columns = [
      {
        title: "阶段名称",
        key: "phaseName",
        dataIndex: "phaseName",
        slots: { customRender: "phaseName" },
      },
      {
        title: "阶段描述",
        key: "phaseDescribe",
        dataIndex: "phaseDescribe",
        slots: { customRender: "phaseDescribe" },
      },
      {
        title: "客户数",
        key: "clientNum",
        dataIndex: "clientNum",
        slots: { customRender: "clientNum" },
      },
      {
        title: "操作",
        key: "action",
        width: "15%",
        slots: { customRender: "action" },
      },
    ];

    return {
      columns,
      confirmLoading: false,
    };
  },
  setup: function () {
    const editVisible = ref(false);
    const rowsData = reactive({ stagesData: [] });
    const rowsData2 = reactive({ stagesData: [] });
    const countData = ref(0);
    const nameData = ref();
    const formState = ref({});
    const hint = ref(false);
    const isLoading = ref(false);

    const findAllStages = async () => {
      rowsData.stagesData = await CustomerStateApi.findAllStages({});
      rowsData2.stagesData = JSON.parse(JSON.stringify(rowsData.stagesData));
    };

    const deleteStageBefore = _.throttle(async (record) => {
      if (Number(record.count) > 0) {
        countData.value = record.count;
        nameData.value = record.name;
        hint.value = true;
      } else {
        await CustomerStateApi.deleteStage({ id: record.id });
        await findAllStages();
        message.success("删除成功");
      }
    }, 2000);

    const closeHint = async () => {
      hint.value = false;
    };
    const handleSend2 = async () => {
      hint.value = false;
    };

    // 初始化表格拖动
    const initSortable = () => {
      const mytb = document.querySelector("#mytb tbody");
      new Sortable(mytb, {
        animation: 150,
        chosenClass: "sortable-chosen",
        dragClass: "sortable-drag",
        forceFallback: true,
        sort: true,
        async onEnd({ oldIndex, newIndex }) {
          if (oldIndex == newIndex) {
            return;
          }
          isLoading.value = true;
          let currRow = rowsData2.stagesData.splice(oldIndex, 1)[0];
          rowsData2.stagesData[newIndex + 1] = rowsData2.stagesData[newIndex];
          rowsData2.stagesData[newIndex] = currRow;

          let dragId = rowsData2.stagesData[newIndex].id; //拖动行的id
          let oneId, twoId;
          //拖动行的前一行
          if (rowsData2.stagesData[newIndex - 1]) {
            oneId = rowsData2.stagesData[newIndex - 1].id;
          } else {
            oneId = 0;
          }
          //拖动行的后一行
          if (rowsData2.stagesData[newIndex + 1]) {
            twoId = rowsData2.stagesData[newIndex + 1].id;
          } else {
            twoId = 0;
          }
          let params = {
            stageId: dragId,
            preStageId: oneId,
            afterStageId: twoId,
          };
          rowsData2.stagesData = await CustomerStateApi.sort(params);
          isLoading.value = false;
          message.success("排序完成");
        },
      });
    };
    const afterClose = async () => {
      editVisible.value = false;
      await findAllStages();
    };
    findAllStages();
    onMounted(async () => {
      await initSortable();
    });

    const editEvent = async (record) => {
      if (record) {
        formState.value = record;
      } else {
        formState.value = {
          name: "",
          remark: "",
        };
      }
      editVisible.value = true;
    };

    return {
      formState,
      findAllStages,
      editEvent,
      editVisible,
      afterClose,
      deleteStageBefore,
      hint,
      countData,
      closeHint,
      handleSend2,
      nameData,
      rowsData,
      rowsData2,
      isLoading,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-input-textarea-show-count::after) {
  position: relative;
  margin-top: -45px;
  margin-right: 15px;
}
:deep(.ant-table-tbody),
:deep(.sortable-drag) {
  cursor: url("../../../assets/m_move_compress8.svg"), auto;
}
.title-mark {
  margin: 0;
}
</style>