<template>
  <a-modal title="添加/编辑阶段" v-model:visible="editVisible" width="600px" :confirm-loading="confirmLoading"
    @ok="handleSend" @cancel="afterClose">
    <a-form :model="formState" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item ref="name" label="阶段名称" name="name">
        <a-input v-model:value="formState.name" placeholder="请输入阶段名称" maxlength="10" />
      </a-form-item>

      <a-form-item label="阶段描述" name="remark">
        <a-textarea v-model:value="formState.remark" placeholder="请输入阶段描述" :auto-size="{ minRows: 5 }" showCount
          :maxlength="100" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import CustomerStateApi from "@/service/api/CustomerStateApi.js";
import { message } from "ant-design-vue";
import _ from "lodash";

export default defineComponent({
  name: "CustomerModelEdit",
  props: ["formStateData"],
  setup: function (props, context) {
    const editVisible = ref(true);
    const formState = ref({
      name: "",
      remark: "",
    });
    const newStart = ref(false);
    // 判断新增状态
    if (!props.formStateData.name) {
      newStart.value = true;
    }

    onMounted(async () => {
      const { formStateData } = props;
      formState.value = formStateData;
    });

    const inputEmoji = (substring) => {
      for (let i = 0; i < substring.length; i++) {
        let hs = substring.charCodeAt(i);
        if (0xd800 <= hs && hs <= 0xdbff) {
          if (substring.length > 1) {
            let ls = substring.charCodeAt(i + 1);
            let uc = (hs - 0xd800) * 0x400 + (ls - 0xdc00) + 0x10000;
            if (0x1d000 <= uc && uc <= 0x1f77f) {
              return true;
            }
          }
        } else if (substring.length > 1) {
          let ls = substring.charCodeAt(i + 1);
          if (ls == 0x20e3) {
            return true;
          }
        } else {
          if (0x2100 <= hs && hs <= 0x27ff) {
            return true;
          } else if (0x2b05 <= hs && hs <= 0x2b07) {
            return true;
          } else if (0x2934 <= hs && hs <= 0x2935) {
            return true;
          } else if (0x3297 <= hs && hs <= 0x3299) {
            return true;
          } else if (
            hs == 0xa9 ||
            hs == 0xae ||
            hs == 0x303d ||
            hs == 0x3030 ||
            hs == 0x2b55 ||
            hs == 0x2b1c ||
            hs == 0x2b1b ||
            hs == 0x2b50
          ) {
            return true;
          }
        }
      }
    };

    const afterClose = () => {
      context.emit("afterClose", {});
    };
    const rules = {
      name: [
        {
          required: true,
          message: "请输入阶段名称",
          trigger: "blur",
        },
      ],
      remark: [
        {
          required: true,
          message: "请输入阶段描述",
          trigger: "blur",
        },
      ],
    };

    const handleSend = _.throttle(
      async () => {
        if (
          formState.value.name.trim() === "" ||
          formState.value.remark.trim() === ""
        ) {
          message.warning("不能只输入空");
          return;
        }
        if (
          inputEmoji(formState.value.name) ||
          inputEmoji(formState.value.remark)
        ) {
          message.warning("不能输入表情");
          return;
        }
        if (!formState.value.name) {
          message.warning("请输入阶段名称");
          return;
        }

        if (!formState.value.remark) {
          message.warning("请输入阶段描述");
          return;
        }
        if (!newStart.value) {
          await CustomerStateApi.updateStage(formState.value);
        } else {
          await CustomerStateApi.createStage(formState.value);
        }
        message.success("提交成功");
        context.emit("afterClose", {});
      },
      2000,
      { trailing: false }
    );

    return {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 20,
      },
      formState,
      rules,
      handleSend,
      editVisible,
      afterClose,
      newStart,
      inputEmoji,
    };
  },
});
</script>
<style scoped>
:deep(.ant-input-textarea-show-count::after) {
  position: relative;
  margin-top: -45px;
  margin-right: 15px;
}
</style>